@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

html {
    @apply text-sm font-medium lg:text-xs lg:font-normal;
}

body {
    @apply standard-text-color bg-[#f5f7fb] dark:bg-[#191e24];
}

.border-btn {
    @apply border border-gray-200 shadow-sm disabled:shadow-none dark:border-gray-800;
}

.disable {
    @apply disabled:cursor-not-allowed disabled:border-gray-200/50 disabled:bg-gray-50 disabled:text-gray-600/50 disabled:hover:border-gray-100/50 disabled:hover:text-gray-600/50 dark:disabled:border-gray-700 dark:disabled:bg-[#292c31] dark:disabled:text-gray-600 dark:disabled:hover:border-gray-700 dark:disabled:hover:text-gray-600;
}

input:checked + div {
    @apply border-blue-500;
}

input:checked + div svg {
    @apply block;
}


.picker > div > div:last-child {
    @apply relative lg:-right-4 lg:text-sm;
}

.checkbox {
    @apply h-6 w-6 cursor-pointer appearance-none rounded-md border border-gray-300 text-gray-400 focus:outline-none focus:ring-transparent disabled:cursor-not-allowed disabled:bg-gray-200 dark:border-gray-700 dark:bg-gray-800 dark:focus:outline-none dark:focus:ring-transparent dark:disabled:bg-gray-800 dark:disabled:text-gray-400;
}

[type="checkbox"]:checked {
    @apply border-gray-300 bg-white hover:border-gray-300 hover:bg-white focus:border-gray-300 focus:bg-white dark:border-gray-700 dark:bg-gray-800 dark:hover:border-gray-700 dark:focus:border-gray-700;
}

[type="checkbox"].green-text-color:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%2315803d%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
}

[type="checkbox"].red-text-color:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%23b91c1c%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
}

[type="checkbox"].standard-text-color:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%234b5563%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
}

[type="checkbox"].black-text-color:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox=%270 0 16 16%27 fill=%27%232d3748%27 xmlns=%27http://www.w3.org/2000/svg%27%3e%3cpath d=%27M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z%27/%3e%3c/svg%3e");
}

.checkbox-label {
    @apply ml-2 cursor-pointer;
}

.green-text-color {
    @apply text-green-700 dark:text-green-700;
}

.green-border-color {
    @apply border-green-700 dark:border-green-700;
}

.green-bg-color {
    @apply bg-green-700;
}

.black-text-color {
    @apply text-black dark:text-gray-400;
}

.red-text-color {
    @apply text-red-700;
}

.red-border-color {
    @apply border-red-700 dark:border-red-700;
}

.red-bg-color {
    @apply bg-red-700;
}

.standard-text-color {
    /*@apply text-yellow-500 dark:text-yellow-500;*/
    @apply text-gray-600 dark:text-gray-400;
}

.standard-border-color {
    @apply border-gray-600 dark:border-gray-600;
}

.standard-bg-color {
    @apply bg-black;
}

.white-text-color {
    @apply text-white dark:text-gray-400;
}

.w-inherit {
    width: inherit;
}

.swiper-slide {
    height: auto !important;
}

.swiper {
    height: 100% !important;
}

.h-fulled {
    height: 95%;
}

@layer base {
    @font-face {
        font-family: "Cream";
        font-weight: 400;
        src: url("../../public/assets/fonts/Cream.woff") format("woff");
    }

    @font-face {
        font-family: "Diving";
        font-weight: 400;
        src: url("../../public/assets/fonts/Diving.ttf") format("woff");
    }

    .std-bg {
        @apply border-gray-200 bg-white dark:border-gray-800 dark:bg-[#1d232a];
    }

    .day-bg {
        @apply bg-white dark:bg-[#1d232a];
    }


    .title-bar {
        @apply space-x-1 text-5xl;
        font-family: "Cream", sans-serif;
    }

    .span-title {
        @apply space-x-1 text-5xl;
        font-family: "Diving", sans-serif;
    }
}

@layer components {
    .base-btn {
        @apply relative w-full inline-flex items-center rounded-lg px-3 py-2 font-bold lg:text-sm;
    }

    .primary-btn {
        @apply bg-main text-white hover:bg-main_hover dark:bg-main_dark dark:hover:bg-main_dark_hover;
    }

    .secondary-btn {
        @apply bg-transparent hover:bg-[#F4F8FB] disabled:cursor-not-allowed disabled:bg-transparent disabled:text-gray-300 dark:hover:bg-[#191e24] dark:disabled:bg-transparent dark:disabled:text-gray-600;
    }

    .option-btn {
        @apply bg-gray-500 text-white hover:bg-gray-700 dark:bg-gray-700 dark:hover:bg-gray-900;
    }

    .danger-btn {
        @apply bg-red-600 text-white hover:bg-red-700 dark:bg-red-800 dark:hover:bg-red-900;
    }

    .simple-btn {
        @apply rounded-lg hover:bg-white/10;
    }

    .label-form {
        @apply absolute text-lg left-2 top-1/2 -translate-y-1/2 rounded-xl px-2 text-gray-600/50 dark:text-gray-400;
    }

    .label-form,
    .input-form {
        @apply transition-all duration-200;
    }

    .picker > input {
        @apply placeholder-transparent focus:placeholder:text-gray-600/40;
    }

    .picker:has(input ~ div:is(.block)) > .input-form {
        @apply placeholder:text-gray-600/40;
    }

    .picker:has(input:not(:placeholder-shown)) ~ .label-form,
    .picker:has(input ~ div:is(.block)) ~ .label-form,
    .picker:has(input:focus) ~ .label-form,
    input:not(:placeholder-shown) ~ .label-form,
    textarea:not(:placeholder-shown) ~ .label-form,
    select:valid ~ .label-form,
    input:focus ~ .label-form,
    textarea:focus ~ .label-form,
    select:focus ~ .label-form {
        @apply top-0 z-3 origin-[0] -translate-y-1/2 scale-75 transform bg-white font-medium;
    }

    .picker:has(input:focus) ~ .label-form,
    input:focus ~ .label-form,
    textarea:focus ~ .label-form,
    select:focus ~ .label-form {
        @apply text-secondary dark:text-blue-500;
    }

    .input-form {
        @apply z-2 w-full border border-gray-300 bg-transparent px-2.5 pb-2.5 pt-3 lg:pt-4 focus:z-3 focus:border-secondary outline-none focus:ring-0 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-700 dark:bg-gray-950/10 dark:text-gray-400 dark:placeholder-gray-400;
    }

    .input-form.error,
    button.error {
        @apply border-red-600 dark:border-red-400;
    }

    .input-form.error:not(select) {
        @apply z-3;
    }

    .label-required {
        @apply text-red-600 dark:text-red-400;
    }

    /** ACCORDION **/
    .accordionBtn {
        cursor: pointer;
        display: flex;
        width: 100%;
    }

    .accordionContent {
        transition: height 0.25s cubic-bezier(0, 0, 0, 1);
    }

    .accordionChevron {
        margin-left: auto;
        transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    }

    .accordionBtnExpanded .accordionChevron {
        transform: rotate(180deg);
    }
}
